import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Header from "./header"
import Footer from "./footer"

import "../style/style.scss"

const Layout = ({ title, home, children }) => {
  const [menu, setMenu] = useState(false);
  const [position, setPosition] = useState("");

  const toggleMenu = () => {
    let pos = menu
      ? position
      : (document.body.style.top = `-${window.scrollY}px`);
    setMenu(!menu);
    setPosition(pos);

    if (!menu) {
      window.scrollTo(0, parseInt(pos || "0") * -1);
    }
  };


  return (
    <main className={menu ? "no-scroll" : ""}>
      <Header mainMenu={toggleMenu} home={home} title={"IPROPERTY"} />
      <div id="site-main" className="site-main">
        <div id="swup">{children}</div>
      </div>
      <Footer />
    </main>
  );
}

export default injectIntl(Layout)
