import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEO from "../components/Seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <h1><FormattedMessage id='not_found' /></h1>
      <p><FormattedMessage id='not_found_desc' /></p>
    </Layout>
  );
};


export const notFoundPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const NotFoundPageContainer = (props) => {
  const data = useStaticQuery(notFoundPageQuery);
  return <NotFoundPage {...props} data={data} />;
};

export default NotFoundPageContainer;