import React, { useState, useEffect } from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import LanguageSwitcher from "./langswitcher";

import Logo from "../../content/assets/logo.svg";
import LogoDark from "../../content/assets/logo_dark.svg";

const Header = ({ title, home, mainMenu }) => {
  const [menuActive, setMenuActive] = useState(false);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    if (home) {
      const onScroll = () => {
        const newIsTop = window.scrollY < window.innerHeight;
        if (newIsTop !== isTop) {
          setIsTop(newIsTop);
        }
      };
      document.addEventListener("scroll", onScroll);
      return () => document.removeEventListener("scroll", onScroll);
    }
  }, [home, isTop]);

  const toggleMenu = (e) => {
    setMenuActive(!menuActive);
    mainMenu();
  };

  return (
    <header>
      {home ? (
        !isTop ? (
          <div className="main-menu menu-fade">
            <div className="logo">
              <Link to="/">
                <img src={LogoDark} alt="logo" />
                {title}
              </Link>
            </div>
            <div className="links">
              <Link to="/about">
                <FormattedMessage id="links.about" />
              </Link>
              <Link to="/projects">
                <FormattedMessage id="links.projects" />
              </Link>
              <LanguageSwitcher />
            </div>
          </div>
        ) : null
      ) : (
        <div className="main-menu">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={LogoDark} alt="logo" />
              {title}
            </Link>
          </div>
          <div className="links">
            <Link to="/about">
              <FormattedMessage id="links.about" />
            </Link>
            <Link to="/projects">
              <FormattedMessage id="links.projects" />
            </Link>
            <LanguageSwitcher />
          </div>
        </div>
      )}

      <div className="main-mobile">
        <div className="logo">
          <Link to="/">
            {" "}
            <img src={LogoDark} alt="logo" />
            {title}
          </Link>
        </div>
        <div className="links">
          <div
            onClick={toggleMenu}
            className={"icon " + (menuActive ? "active" : "")}
          >
            <div className="line"></div>
          </div>
        </div>

        <div
          className={
            "mobile-menu-content " +
            (menuActive ? "active" : "hidden")
          }
        >
          <div className="mobile-links">
            <img src={LogoDark} alt="logo" />
            <Link to="/">
              <FormattedMessage id="links.home" />
            </Link>
            <Link to="/about">
              <FormattedMessage id="links.about" />
            </Link>
            <Link to="/projects">
              <FormattedMessage id="links.projects" />
            </Link>
            <Link to="/contact">
              <FormattedMessage id="links.contact" />
            </Link>
          </div>

          <div className="mobile-lang">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;