import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

import LogoDark from "../../content/assets/logo_dark.svg";

import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";

const Footer = ({ data }) => {
  const site = data.site.siteMetadata;

  return (
    <footer className='site-foot'>
      <div className='footer-left'>
        <img src={LogoDark} alt='logo' />
        <span className='name'>
          <FormattedMessage id='name' />
        </span>
        <span className='slogen'>
          <FormattedMessage id='slogen' />
        </span>
        <div>
          &copy; {new Date().getFullYear()} &mdash;{" "}
          <FormattedMessage id='all_rights' />
        </div>
        <div className='footer_info'>
          <FormattedMessage id='valtoztatas_jog' />
        </div>
      </div>

      <div className='footer-right'>
        <span className='block'>
          <a className='phone-link' href={`tel:${site.callable}`}>
            <IoIosCall /> {site.phone}
          </a>
        </span>
        <span className='block'>
          <a className='phone-link' href={`mailto:${site.mail}`}>
            <MdEmail /> {site.mail}
          </a>
        </span>
        <Link to='/contact'>
          <button role='button' className='outline d-block'>
            <FormattedMessage id='contact_btn' />
          </button>
        </Link>
        <div className='bottom'>
          <Link className='footer-link' to='/adatkezeles'>
            <FormattedMessage id='adatkezeles' />
          </Link>
          <Link className='footer-link' to='/impressum'>
            <FormattedMessage id='impressum' />
          </Link>
        </div>
      </div>
    </footer>
  );
};

const footerQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        slogen
        social {
          twitter
        }
        phone
        mail
        callable
      }
    }
  }
`;


const FooterContainer = (props) => {
  const data = useStaticQuery(footerQuery);
  return <Footer {...props} data={data} />;
};

export default FooterContainer;