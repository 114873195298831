import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "EN",
  hu: "HU",
}

const LanguageSwitcher = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language =>
          currentLocale !== language ? (
            <a className="langswitch" key={language} onClick={() => changeLocale(language)}>
              {languageName[language]}
            </a>
          ) : null
        )
      }
    </IntlContextConsumer>
  )
}

export default LanguageSwitcher
